import React, { useContext, useState } from "react";

import AuthContext from "../../context/AuthContext";

import Button from "../../components/ui/Button";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
import Waleed from "../../components/svgs/Waleed";
import YearsBooks from "../../components/svgs/YearsBooks";
import Shapes from "../../components/svgs/Shapes";
import Waves from "../../components/ui/Waves";

import bg from "../../assets/bg-star.png";
import bggradient from "../../assets/bg-gradient.svg";
import profile from "../../assets/profile.png";
import logolarge from "../../assets/logolarge.png";

const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);

    return (
        <>
            {/*  */}
            <section className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden mb-40">
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-100"
                    style={{
                        backgroundImage: "url(" + bggradient + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                ></div>
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-10"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                ></div>
                <Container className="h-full relative z-10 font-graph pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row flex-col-reverse h-full space-y-20 space-y-reverse md:space-y-0">
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="flex justify-center md:items-start items-center flex-col clr-white smooth space-y-6 md:space-y-8">
                                <h1 className="font-bigmax font-w-bold relative max-w-md">
                                    <div className="hidden">أستاذي وليد محسن</div>
                                    <img src={logolarge} alt="logo" />
                                </h1>
                                <div className="space-y-2 flex flex-col md:items-start items-center justify-center">
                                    <div className="w-[2px] h-20 bg-white origin-top-right md:rotate-45"></div>
                                    <h2 className="font-big text-slate-100 font-w-bold">
                                        في اللغة العربية
                                    </h2>
                                    <div className="font-big relative text-slate-100">
                                        للثانوية العامة
                                    </div>
                                </div>
                                <div className="flex space-x-2 space-x-reverse">
                                    {!token ? (
                                        <Button color="yellow" element="Link" to="/register">
                                            أنشئ حسابك الآن !
                                        </Button>
                                    ) : (
                                        <Button color="yellow" element="Link" to="/me/user/courses">
                                            كورساتي
                                        </Button>
                                    )}
                                    <Button element="a" href="https://booxtore.net">
                                        شراء الكتب
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md">
                                <img src={profile} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
