import React from "react";

import logoTextDark from "../assets/navbar/logodark.png";
import logoTextLight from "../assets/navbar/logolight.png";
import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import { Icon } from "@iconify/react";
import CenterIcon from "../components/ui/CenterIcon";

export const navLogoComponent = (darkmode, isNavbarHidden, scrolling) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        <img
            className="h-10 sm:h-14 w-auto"
            src={(!scrolling && isNavbarHidden) || darkmode ? logoTextDark : logoTextLight}
            alt="Workflow"
        />
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    ` ${!isRTL ? "en" : ""} ${scrolling && "bg-primary-container"}`;
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:text-orange-500"
        to="/register"
        className="bg-orange-500 bg-opacity-100 hover:bg-opacity-0 clr-white border-2 border-orange-500 group"
        isClrPrimary={false}
    >
        <span className="flex-center-both">انشئ حسابك !</span>
        <span className="flex-center-both font-h2 dark:text-orange-200 smooth text-orange-800 group-hover:text-orange-500 dark:group-hover:text-orange-500">
            <Icon icon="ph:address-book-duotone" />
        </span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border border-yellow-400 border-opacity-0"
        defualtHover="hover:dark:border-opacity-100 hover-shadow"
    >
        <span className="flex-center-both space-x-1">
            <span className="text-yellow-400">الدخول</span>
            <span>تسجيل</span>
        </span>
        <CenterIcon icon="majesticons:door-enter" className="text-yellow-400 font-h2" />
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <CenterIcon icon="majesticons:door-enter" className="text-yellow-400 font-h2" />
        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <span className="flex-center-both font-h2 text-yellow-200">
            <Icon icon="ph:address-book-duotone" />
        </span>
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);
