import React, { useContext, useEffect } from "react";

import MainSection from "../sections/home/MainSection";
import ProfileSection from "../sections/home/ProfileSection";
import Wise1Section from "../sections/home/Wise1Section";
import { ScrollingProvider } from "../context/ScrollingContext";
import Wise2Section from "../sections/home/Wise2Section";
import YearsSection from "../sections/home/YearsSection";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Home = () => {
    const navigate = useNavigate();

    const { token, user } = useContext(AuthContext);

    useEffect(() => {
        if (token) {
            console.log(user.year);
        }
    }, [token]);
    return (
        <>
            <MainSection />

            <ProfileSection />

            <ScrollingProvider>
                <Wise1Section />
            </ScrollingProvider>
            <YearsSection />
            <ScrollingProvider>
                <Wise2Section />
            </ScrollingProvider>
        </>
    );
};

export default Home;
