import React from "react";
import Container from "../../components/ui/Container";

import profile from "../../assets/profile.png";
import Waves from "../../components/ui/Waves";

// import bg from "../../assets/bg-arabic.png";
import bg2 from "../../assets/bg.png";
import Button from "../../components/ui/Button";

const ProfileSection = ({ year = false }) => {
    console.log(year);
    return (
        <div className="bg-orange-700 dark:bg-orange-700 smooth text-slate-900 relative">
            <div
                className="inset-0 absolute w-full h-full overflow-hidden opacity-10  smooth"
                style={{
                    backgroundImage: "url(" + bg2 + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                    backgroundRepeat: "repeat-y",
                }}
            >
                {/* <img src={bg} alt="bg" /> */}
                <div className="absolute inset-0 w-full h-full bg-gradient-bg smooth"></div>
            </div>
            <Waves className="fill-primary-container transform -translate-y-1/4 scale-y-[.5] rotate-180 z-10 absolute right-0 top-0 left-0 w-full" />
            <Container className="relative z-10">
                <div className="flex sm:flex-row flex-col sm:space-x-5 sm:space-x-reverse sm:space-y-0 space-y-10">
                    <div className="sm:basis-1/2 h-full flex-center-both">
                        <div className="w-full max-w-md">
                            <img src={profile} alt="profile" />
                        </div>
                    </div>
                    <div className="sm:basis-1/2 flex-center-both">
                        <div className="w-full h-full flex justify-center items-center sm:items-start flex-col font-taj">
                            <div>
                                <div className="font-w-bold font-h2 text-yellow-300">معلمك</div>
                                <div className="font-w-bold font-bigmax font-taj text-slate-100">
                                    وَلِيْد مُحْسِن
                                </div>
                                {year == 3 ? (
                                    <Button
                                        element="a"
                                        className="font-cairo mt-1 inline-block"
                                        href="https://www.facebook.com/groups/633539984780426/"
                                        color="blue"
                                    >
                                        الانضمام لجروب الفيسبوك
                                    </Button>
                                ) : year == 2 ? (
                                    <Button
                                        element="a"
                                        className="font-cairo mt-1 inline-block"
                                        href="https://www.facebook.com/groups/592711202564122/"
                                        color="blue"
                                    >
                                        الانضمام لجروب الفيسبوك
                                    </Button>
                                ) : year == 1 ? (
                                    <Button
                                        element="a"
                                        className="font-cairo mt-1 inline-block"
                                        href="https://www.facebook.com/groups/753973432598964/"
                                        color="blue"
                                    >
                                        الانضمام لجروب الفيسبوك
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Waves className="fill-primary-container transform origin-bottom scale-y-50 md:scale-y-100" />
        </div>
    );
};

export default ProfileSection;
