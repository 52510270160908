import React, { useEffect, useState } from "react";
import InputIcon from "../../components/form/elements/InputIcon";
import AdminForm from "../../components/ui/AdminForm";
import auth from "../../services/authServices";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";
import AdminToAdminUserNote from "./AdminToAdminUserNote";

const AdminToAdminNoteForm = ({ user = { id: 0 }, afterSuccess = () => null }) => {
    const [data, setData] = useState({
        note: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const inputFields = [
        {
            id: "note",
            togglePassword: true,
            placeholder: "اترك ملاحظة للأدمن",
            icon: <InputIcon icon="simple-line-icons:note" />,
        },
    ];
    const handleSubmit = (e) => {
        // return;
        setIsLoading(true);

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            modal.message({
                title: "هل انت متأكد",
                text: ``,
                icon: "info",
                // button: "تأكيد",
                buttons: {
                    confirm: "تأكيد",
                    cancel: "إلغاء",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        handleAdminToAdminNoteChange();
                    } else {
                        setIsLoading(false);
                    }
                },
            });
        });
    };
    const handleAdminToAdminNoteChange = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            const { data: response } = await http.post(
                `/api/users/${user.id}/admin_to_admin_user_note`,
                data,
                config
            );

            modal.message({
                title: `عملية ناجحة`,
                text: "تم اضافة الرسالة بنجاح",
                callback: () => {
                    setData({
                        note: "",
                    });
                    setIsLoading(false);
                    afterSuccess();
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };
    const handleDelete = async (id) => {
        setIsLoading(true);

        modal.message({
            title: "هل انت متأكد",
            text: `هل انت متأكد من حذف الملاحظة؟`,
            icon: "info",
            // button: "تأكيد",
            buttons: {
                confirm: "تأكيد",
                cancel: "إلغاء",
            },
            callback: async (e) => {
                if (e && e !== "cancel") {
                    const adminToken = auth.getAdminToken();
                    const config = auth.getAdminAuthConfig(adminToken);
                    try {
                        const { data: response } = await http.delete(
                            `/api/users/${user.id}/admin_to_admin_user_note/${id}`,
                            config
                        );

                        modal.message({
                            title: `عملية ناجحة`,
                            text: "تم حذف الملاحظة بنجاح",
                            callback: () => {
                                setIsLoading(false);
                                afterSuccess();
                            },
                        });
                    } catch (error) {
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            },
        });
    };

    useEffect(() => {
        // setData({ note: user.note });
    }, [user]);

    return (
        <AdminForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            buttonText="اضافة ملاحظة للأدمن"
            className="space-y-4 py-0 pb-16"
        >
            <div className="font-h1 text-center font-w-bold">ملاحظات الادمن</div>
            <div className="pb-4 space-y-5">
                {/* <div className="w-full px-2 py-3 bg-yellow-200 text-slate-600 rounded-md shadow-md text-center">
                    
                </div> */}
                {user.admin_to_admin_user_notes.length > 0 ? (
                    user.admin_to_admin_user_notes.map((note) => (
                        <AdminToAdminUserNote
                            key={note.id}
                            note={note}
                            handleDelete={handleDelete}
                            isLoading={isLoading}
                        />
                    ))
                ) : (
                    <div className="w-full px-2 py-3 bg-yellow-200 text-slate-600 rounded-md shadow-md text-center">
                        لا توجد ملاحظات سابقة
                    </div>
                )}
            </div>
            {inputFields.map((input, key) =>
                renderInputFields(
                    key,
                    input.handleChange ? input.handleChange : handleChange,
                    data,
                    setData,
                    errors,
                    input
                )
            )}
        </AdminForm>
    );
};

export default AdminToAdminNoteForm;
