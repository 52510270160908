import React from "react";
import Categorie from "../../sections/home/CoursesSection/Categorie";
import { isYear4CoursesCustomTab } from "../../services/defaultSettings";

const UserCourses = ({ isMiniCoursesOnly = false }) => {
    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            <Categorie
                api={`/api/sellables/subscribed${
                    isYear4CoursesCustomTab ? (isMiniCoursesOnly ? "?year=4" : "?not_year_4=1") : ""
                }`}
                grid="small"
                noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
            />
        </div>
    );
};

export default UserCourses;
